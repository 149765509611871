<template>
  <div class="fetaured-wrap mt-4 mb-4" v-if="showFeaturePlan">
    <div class="container">
      <div class="row">
        <div class="col-12" v-if="featuredPlans.length > 0">
          <h2><span class="brand-color">Featured Plans</span> <br /></h2>
        </div>
        <div class="plansGrid-wrap">
          <div>
            <div class="row">
              <div
                class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-4"
                v-for="(plan, index) in featuredPlans"
                v-bind:key="index"
              >
                <div class="plan-block card-block text-start">
                  <img :src="plan.plan_logo" alt="Critical" class="img-fluid" />
                  <h3>
                    <a
                      href="#"
                      v-on:click="nextPage(plan.plan_id, plan.plan_name)"
                    >
                      {{ plan.plan_name }}</a
                    >
                  </h3>
                  <div v-html="plan.plan_short_text"></div>
                  <a
                    class="more check-btn"
                    v-on:click="nextPage(plan.plan_id, plan.plan_name)"
                    >More Info</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Api from "../includes/Api";

export default {
  name: "FeaturedPlans",
  data: function () {
    return {
      agent_id: "",
      portal_id: "",
      group_id: "",
      portalId: "",
      open_type: "",
      eprocess: "",
      landing_type:"",
      featuredPlans: [],
      showFeaturePlan: false
    };
  },
  mounted() {
    let app = this;
    // app.getMember();
    app.landing_type = window.localStorage.getItem("open_type");
    app.group_id = window.localStorage.getItem("group_id");
    app.agent_id = window.localStorage.getItem("agent_id");
    app.portal_id = window.localStorage.getItem("portal_id");
    app.eprocess = window.localStorage.getItem("eprocess");
    app.open_type = window.localStorage.getItem("open_type");
    if (app.portal_id && app.group_id) {
      let app = this
      app.showFeaturePlan = true
      app.getFeaturedPlans();
    }else {
      app.getPlans()
    }
  },
  methods: {
    nextPage: function (pid, planName) {
      console.log(pid);
      this.$router.push("plan/" + pid + "/" + planName);
    },
    getFeaturedPlans: function () {
      let app = this;
      let url;
      url = process.env.VUE_APP_API_BASE + "/get-featured-plans";
      if (app.eprocess === "rep") {
        axios
          .get(url, {
            params: {
              portal_id: app.portal_id,
              agent_id: app.agent_id,
              group_id: app.group_id,
              eprocess: app.eprocess,
              open_type: app.open_type,
            },
          })
          .then(function (response) {
            if (response.data.status == "success") {
              response.data.data.forEach(function (item) {
                app.featuredPlans.push(item);
              });
              if (response.data.data.length === 0) {
                app.showFeaturePlan = false
              }
            }
            console.log(
              "here is suggested plans",
              JSON.stringify(app.featuredPlans)
            );
          })
          .catch(function (error) {
            app.showFeaturePlan = false
            console.log(error);
          });
      } else {
        axios
          .get(url, {
            params: {
              portal_id: app.portal_id,
              group_id: app.group_id,
              agent_id: app.agent_id,
              eprocess: app.eprocess,
              open_type: app.open_type,
            },
          })
          .then(function (response) {
            if (response.data.status == "success") {
              response.data.data.forEach(function (item) {
                app.featuredPlans.push(item);
              });
              if (response.data.data.length === 0) {
                app.showFeaturePlan = false
              }
            }
            console.log(
              "here is suggested plans",
              JSON.stringify(app.featuredPlans)
            );
          })
          .catch(function (error) {
            app.showFeaturePlan = false
            console.log(error);
          });
      }
    },
    getPlans: function () {
      let app = this;
      if (
        (location.hostname == process.env.VUE_APP_SITE_HOST_NAME &&
          app.landing_type == "agent" &&
          app.$route.params.landing) ||
        (location.hostname == process.env.VUE_APP_SITE_HOST_NAME &&
          app.landing_type == "group" &&
          app.$route.params.landing)
      ) {
        Api.get(
          "/get-domainSite-infromation?site_domain=" +
            window.btoa(unescape(encodeURIComponent(location.host)))
        ).then((response) => {
          if (response.data.statusCode == 200) {
            if (app.landing_type !== "group") {
              window.localStorage.setItem("group_id", response.data.data.gid);
            }
            window.localStorage.setItem("portal_id", response.data.data.gid);
            window.localStorage.setItem("enroll_type", "new-group-member");
            app.group_id = window.localStorage.getItem("group_id");
            app.agent_id = window.localStorage.getItem("agent_id");
            app.portal_id = window.localStorage.getItem("portal_id");
            app.eprocess = window.localStorage.getItem("eprocess");
            app.open_type = window.localStorage.getItem("open_type");
            app.showFeaturePlan = true
            app.getFeaturedPlans()
          }
        });
      }
    },
  },
};
</script>

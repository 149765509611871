<template>
  <section class="page-wrap">
    <div class="banner-wrap pb-5">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb">
              <ul>
                <li><a href="#" @click.prevent="homePage">Home</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xxl-5 col-xl-5 col-lg-5 col-12 mb-5">
            <div v-if="!isHidden">
              <h1>Welcome <br><span class="brand-color"><strong>{{ group_name }}</strong></span> to the <strong>Benefit
                Store</strong> <br> Let's <strong>Get Started</strong></h1>
              <span class="banner-ques">Who do you want to enroll for ?</span>

              <div class="enroll-btn mb-4" v-if="!isHiddenPortal1">
                <a href="javascript:void(0);" class="dark-btn" v-on:click="isHiddenPortal1 = true">New Member</a>
                <a class="brand-btn" href="#" id="currentMember" @click="memberPage">Current Member</a>
              </div>
            </div>
            <div class="card-block" v-if="isHidden">
              <form action="">
                <div class="form-group row">
                  <div class="col-xxl-6 mb-4">
                    <label for="">Zip Code</label>
                    <input type="text" name="" placeholder="Enter your Zip Code" class="custom-input">
                  </div>
                  <div class="col-xxl-6 mb-4">
                    <label for="">Gender</label>
                    <select name="" id="" class="custom-input">
                      <option value="null">Select Gender</option>
                      <option value="">Male</option>
                      <option value="">Female</option>
                      <option value="">Others</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xxl-6 mb-4">
                    <label for="">Choose Tier</label>
                    <select name="" id="" class="custom-input">
                      <option value="null">Choose Tier</option>
                      <option value="IO">Member Only</option>
                      <option value="IS">Member + Spouse</option>
                      <option value="IC">Member + Child(ren)</option>
                      <option value="IF">Family</option>
                    </select>
                  </div>
                  <div class="col-xxl-6 mb-4">
                    <label for="">DOB</label>
                    <date-picker
                        input-class="custom-input"
                        placeholder="Choose DOB"
                        type="date"
                        v-model="value"
                        value-type="format"></date-picker>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xxl-6 mb-4">
                    <button class="dark-univ-btn" v-on:click="isHidden = false">Back</button>
                  </div>
                  <div class="col-xxl-6 mb-4">
                    <button class="univ-btn" type="submit" @click="submitAPI"> Submit</button>
                  </div>
                </div>
              </form>
              <div class="col-xxl-6 mb-4">
                <button type="submit" class="univ-btn" id="startEnroll" @click="choosePortal">Submit</button>
              </div>
            </div>
            <div v-if="!isHiddenPortal2">
              <div class="card-block" v-if="isHiddenPortal1">
                <form action="">
                  <div class="col-12 mb-4">
                    <!--                  <label for="">Choose an option to enroll a new member:</label>-->
                    <!--                  <a href="javascript:void(0);" class="dark-btn text-center w-100" @click="nextPageDefault">Start New Member Enrollment</a>
                                      <br>
                                      <br>
                                      <h3> OR </h3>
                                      <br>
                                      <label for="">Choose an option to enroll a new member:</label>
                                      <select name="" class="custom-input" v-model="group_id" id="group_id">
                                        <option v-for="item in groupData" :value="item.gid" v-bind:key="item.gid">{{item.gname}}</option>
                                      </select>
                                      <p style="color:red;" v-if="platformError['error'] == true">{{groupErr['message']}}</p>-->
                    <label for="">Choose Enrollment Portal</label>
                    <select name=""
                            class="custom-input"
                            v-model="platformId"
                            :class="{'invalid': isPlatFormIdInvalid}"
                            id="platformId">
                      <option v-for="item in platforms" :value="item.platform_group_id" v-bind:key="item.platform_group_id">{{item.platform_name}}</option>
                    </select>
                    <div class="form-group__message text-left ml-3 mt-2"  v-if="isPlatFormIdInvalid">
                      Choose enroll for new member
                    </div>
                    <p style="color:red;" v-if="platformError['error'] == true">{{platformError['message']}}</p>
                  </div>
                  <div class="enroll-btn agent-btn row">
                    <div class="col-6">
                      <a href="javascript:void(0);" class="dark-btn mb-3" v-on:click="isHiddenPortal2 = false">Back</a>
                    </div>
                    <br>
                    <div class="col-6">
                      <a href="javascript:void(0);" @click="nextPage"  class="brand-btn  mb-3">Submit</a>
                    </div>


                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-xxl-7 col-xl-7 col-lg-7 col-12">
            <div class="text-center">
              <img :src="bannerImage" alt="banner-image" class="img-fluid">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
export default {
  name: "GroupHome",
  data: function () {
    return {
      groupData: [],
      agent_id: '',
      group_id: '',
      visible: false,
      groupErr: [],
      platforms: [],
      platformError: [],
      platformId: '',
      portalPop: false,
      hideEnroll: false,
      platformPopup: false,
      group_home: '',
      group_name: '',
      isPlatFormIdInvalid: false,
      isHidden: false,
      isHiddenPortal1: false,
      isHiddenPortal2: false,
      bannerImage: 'https://benefit-store-assets.s3.us-east-2.amazonaws.com/banner.png',
    }
  },
  mounted() {
    let app = this;
    // app.getMember();
    app.group_id = window.localStorage.getItem('group_id');
    console.log('group_id', app.group_id)
    app.agent_id = window.localStorage.getItem('AGENT_ID');
    app.group_name = app.$store.state.group.gname;
    // app.getGroups();
    app.getPlatforms();
    app.getGroups();
    window.localStorage.setItem('eprocess', 'group');
    app.fetchCatchInfo();
  },
  methods: {
    getPlatforms: function () {
      let app = this;
      // let agent_id = app.$route.params.id;
      axios.get(process.env.VUE_APP_API_BASE + '/get-enrollment-platforms', {
        params: {
          agent_id: app.agent_id,
        }
      })
          .then(function (response) {
            console.log(response.data.data);
            if (response.data.status == 'success') {
              app.platforms = response.data.data;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    renderToNewEnrollment () {
      this.isHiddenPortal1 = true
      this.hideEnroll = true
    },
    choosePortal: function () {
      let app = this;
      console.log('platforms' + app.platformId);
      app.platformError = [];
      if (app.platformId != '') {
        window.localStorage.setItem('portal_id', this.platformId);
        app.platformPopup = false;
        app.visible = true;
        app.nextPage();
      } else {
        app.platformError['error'] = true;
        app.platformError['message'] = "Please choose a platform";
      }
    },
    getGroups: function() {
      let app = this;
      // let agent_id = app.$route.params.id;
      axios.get(process.env.VUE_APP_API_BASE+'/get-agent-groups', {
        params: {
          agent_id: app.agent_id,
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              app.groupData = response.data.data;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    nextPage: function () {
      if (!this.platformId) {
        this.isPlatFormIdInvalid = true
      } else {
        window.localStorage.setItem('portal_id', this.platformId);
        window.localStorage.setItem('enroll_type', 'new-group-member');
        window.localStorage.setItem('eprocess', 'group');
        this.$bus.$emit('getMenuList');
        window.localStorage.removeItem('tempId')
        this.$router.push('/home');
      }
    },
    nextPageDefault: function() {
      window.localStorage.setItem('group_id', this.platformId);
      window.localStorage.setItem('enroll_type', 'new-member');
      window.localStorage.setItem('eprocess', 'rep');
      this.$bus.$emit('getMenuList');
      this.$router.push('/home');
    },
    showPop() {
      this.portalPop = false;
      this.platformPopup = true;
      this.getPlatforms();
    },
    closePop() {
      this.visible = false;
    },
    closePopPlatforms() {
      this.platformPopup = false;
    },
    closeportalPop() {
      this.portalPop = false;
    },
    memberPage: function () {
      window.localStorage.setItem('enroll_type', 'existing-member');
      this.$router.push('/group-select-member');
    },
    homePage: function(){
      if (this.open_type !== null && typeof(this.open_type) !== 'undefined') {
        if (this.referral !== null && typeof(this.referral) !== 'undefined') {
          this.$router.push('/referrals?rid='+this.referral);
        } else {
          this.$router.push('/home');
        }
      } else {
        if (this.eprocess === 'rep') {
          this.$router.push('/agent-home');
        }else if (this.eprocess === 'applicant') {
          this.$router.push('/member-home');
        } else if (this.eprocess === 'group') {
          if (location.host == process.env.VUE_APP_SITE_HOST_NAME) {
            this.$router.push('/');
          } else {
            this.$router.push('/group-home');
          }
        }
      }
    },
    fetchCatchInfo() {
      let siteDomain = window.btoa(unescape(encodeURIComponent(process.env.VUE_APP_SITE_DOMAIN)))
      // let siteDomain = 'aHR0cHM6Ly9lbnJvbGwucHVyZW5yb2xsLmNvbQ=='
      let url = `${process.env.VUE_APP_API_BASE}/get-external-site-dynamic-information?site_domain=${siteDomain}`
      caches.open('fetch_color_cache').then((cache) => {
        cache.match(url).then(response => {
          response.json().then(data => {
            let getCatchColor = []
            let newObj = {}
            getCatchColor = data
            getCatchColor.map(item => {
              newObj[item.key] = item.value;
            });
            this.bannerImage = newObj["VUE_APP_BANNER_IMAGE"]
          })
        })
      }).catch((err) => {
        console.log(err);
      })
    }
  },
  watch: {
    platformId: function () {
      if (this.platformId) {
        this.isPlatFormIdInvalid = false
      }
    }
  }
}
</script>

<style scoped>
</style>

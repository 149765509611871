<template>
  <div>
    <div class="mt-2 mb-2">
      <h3>Contingent Beneficiary</h3>
    </div>
    <div class="popups">
      <form class="" action="index.html" method="post">
        <div class="form-group row">
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.first_name.required == true">
            <label>First Name <span class="required">*</span></label>
            <input type="text"
                   class="custom-input"
                   @input="$v.formFields.first_name.value.$touch(); removeError();"
                   :class="{'form-control is-invalid':$v.formFields.first_name.value.$error || formErrors['first_name_message']}"
                   placeholder="Enter First Name"
                   v-model="formFields.first_name.value">
            <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.first_name.value.required && $v.formFields.first_name.value.$error">
              Field is required
            </div>
            <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.first_name.value.required && $v.formFields.first_name.value.$error">
              Please enter valid first name
            </div>
            <div class="error_message" v-if="formErrorFlag == true">{{formErrors['first_name_message']}}</div>
          </div>
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.last_name.required == true">
            <label>Last Name <span class="required">*</span></label>
            <input type="text"
                   class="custom-input"
                   placeholder="Enter Last Name"
                   @input="$v.formFields.last_name.value.$touch(); removeError();"
                   v-bind:class="{'form-control is-invalid':$v.formFields.last_name.value.$error || formErrors['last_name_message']}"
                   v-model="formFields.last_name.value">
            <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.last_name.value.required && $v.formFields.last_name.value.$error">
              Field is required
            </div>
            <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.last_name.value.required && $v.formFields.last_name.value.$error">
              Please enter valid last name
            </div>
            <div class="error_message" v-if="formErrorFlag == true">{{formErrors['last_name_message']}}</div>
          </div>
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.birthdate.required == true">
            <label>Date of Birth <span class="required">*</span></label>
            <v-date-picker
                v-model="formFields.birthdate.value"
                :popover="{ visibility: 'click' }"
                :max-date="new Date()"
                @input="$v.formFields.birthdate.value.$touch(); removeError(); convertIntoDateFormat();"
                mode="date">
              <template v-slot="{ inputValue, inputEvents }">
                <input
                    class="custom-input"
                    placeholder="mm/dd/yyyy"
                    :class="{'invalid':$v.formFields.birthdate.value.$error || formErrors['birthdate_message']}"
                    size=10 maxlength=10
                    :value="inputValue.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                    v-on="inputEvents"
                />
              </template>
            </v-date-picker>
            <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.birthdate.value.required && $v.formFields.birthdate.value.$error">
              Field is required
            </div>
            <div class="error_message" v-if="formErrorFlag == true">{{formErrors['birthdate_message']}}</div>
          </div>
        </div>

        <div class="form-group row">


          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.relationship.required == true">
            <label>Relationship <span class="required">*</span></label>
            <b-form-select v-model="formFields.relationship.value"
                           class="custom-input"
                           @input="$v.formFields.relationship.value.$touch(); removeError();"
                           :class="{'form-control is-invalid':$v.formFields.relationship.value.$error || formErrors['relationship_message']}"
                           id="relation_ship_type">
              <option value="null" selected disabled>Select Relationship</option>
              <option v-for="item in relOptions" :value="item[0]" v-bind:key="item[0]">{{item[0]}}</option>
            </b-form-select>
            <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.relationship.value.required && $v.formFields.relationship.value.$error">
              Field is required
            </div>
            <div class="error_message" v-if="formErrorFlag == true">{{formErrors['relationship_message']}}</div>
          </div>
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.ssn.required == true">
            <label>Social Security Number <span class="required">*</span></label>
            <input type="number"
                   class="custom-input"
                   placeholder="Enter Social Security Number"
                   :class="{'form-control is-invalid':$v.formFields.ssn.value.$error || formErrors['ssn_message']}"
                   @input="$v.formFields.ssn.value.$touch();removeError();"
                   v-model="formFields.ssn.value"
                   min="0"
                   oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   maxlength="9">
            <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.ssn.value.required && $v.formFields.ssn.value.$error">
              Field is required
            </div>
            <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.ssn.value.required && $v.formFields.ssn.value.$error">
              Invalid Social Security Number
            </div>
            <div class="error_message" v-if="formErrorFlag == true">{{formErrors['ssn_message']}}</div>
          </div>
        </div>

        <div class="form-group row justify-content-end  ">
          <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12 mb-3">
            <button class="submit univ-btn" type="button" @click="cancelContigencyTab">Cancel</button>
          </div>
          <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12 mb-3">
            <button class="submit univ-btn" type="button" @click="validateContigencyData">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {checkSsn, validName} from "../../utils/validators";
import axios from "axios";

export default {
  name: "ContigencyBeneficary",
  props: {
    relOptions: {
      type: Array,
      require: true
    },
    beneficiary_id: {
      type: String,
      require: false
    },
    contigentData: {
      type: Object,
      require: false
    },
    contigentEdit: {
      type: Boolean,
      require: false
    }
  },
  data: () => ({
    tempId: '',
    formErrors: [],
    formErrorFlag: false,
    formFields: {
      first_name: {
        value: '',
        required: true,
        read_only: false,
        error: false,
        message: '',
      },
      middle_name: {
        value: '',
        required: true,
        read_only: false,
        error: false,
        message: '',
      },
      last_name: {
        value: '',
        required: true,
        read_only: false,
        error: false,
        message: '',
      },
      birthdate: {
        value: '',
        required: true,
        read_only: false,
        error: false,
        message: '',
      },
      ssn: {
        value: '',
        required: true,
        read_only: false,
        error: false,
        message: '',
      },
      relationship: {
        value: null,
        required: true,
        read_only: false,
        error: false,
        message: '',
      },
      formErrorFlag: false,
      formErrors: []
    },
  }),
  validations: {
    formFields: {
      first_name: {
        value: {
          required,
          validName
        }
      },
      last_name: {
        value: {
          required,
          validName
        }
      },
      relationship: {
        value: {
          required
        }
      },
      birthdate: {
        value: {
          required
        }
      },
      ssn: {
        value: {
          required,
          checkSsn
        }
      }
    },
  },
  mounted() {
    let app = this
    app.tempId = window.localStorage.getItem('tempId');
    app.enrollment_type = window.localStorage.getItem('enroll_type');
    app.renderContigentEditData();
  },
  methods: {
    cancelContigencyTab () {
      let app = this
      app.$emit('cancelContigencyTab')
    },
    removeError () {
      this.formErrors = []
    },
    convertIntoDateFormat () {
      let app = this
      let date = app.formFields.birthdate.value
      let dd = date.getDate()
      let mm = date.getMonth() + 1
      let yyyy = date.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      app.formFields.birthdate.value = mm + '/' + dd + '/' + yyyy;
    },
    renderContigentEditData () {
      let app = this
      if (app.contigentEdit) {
        app.beneficiary_id = app.contigentData['beneficiary_id'];
        app.formFields.first_name.value = app.contigentData['first_name'];
        app.formFields.middle_name.value = app.contigentData['middle_name'];
        app.formFields.last_name.value = app.contigentData['last_name'];
        app.formFields.birthdate.value = app.contigentData['birthdate'];
        app.formFields.ssn.value = app.contigentData['ssn'];
        app.formFields.relationship.value = app.contigentData['relationship'];
      }
    },
    validateContigencyData: function() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false
      } else {
        let app = this;
        let params = {
          beneficiary_id:         app.beneficiary_id,
          first_name:             app.formFields.first_name.value,
          last_name:              app.formFields.last_name.value,
          birthdate:              app.formFields.birthdate.value,
          ssn:                    app.formFields.ssn.value,
          relationship:           app.formFields.relationship.value,
          beneficiary_percentage: 0,
          is_contigent: true
        };

        //discard null values
        for (const key of Object.keys(params)) {
          if (params[key] === null) {
            delete params[key];
          }
        }

        axios.post(process.env.VUE_APP_API_BASE + '/validate-single-beneficiary', params)
            .then(function (response) {
              if (response.data.status == 'success') {
                app.$emit('addContigencyBeneficiary', params)
                app.formErrors = [];
                app.formErrorFlag = false
                app.scrollTop();
                app.$v.$reset();
              }
            })
            .catch(function (error) {
              if (error.response.data.status == 'error') {
                error.response.data.data.forEach(function(item) {
                  app.formErrorFlag = true;
                  app.formErrors[item.target_element+'_message'] = item.error_message;
                   });
                 }
              }
            );
      }
    },
  }
}
</script>

<style scoped>

</style>

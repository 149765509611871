<template>
  <section class="page-wrap">
    <div class="banner-wrap"  :style="[siteUrl === 'true' ? {'background-image': 'url(' + goEnrollLandingImage + ')' }: { 'background-image': 'url()'}]">
      <div class="container pt-4">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb" v-if="hostName !== envAssignHostName">
              <ul>
                <li><a href="#" @click.prevent="homePage">Home</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xxl-5 col-xl-5 col-lg-5 col-12 mb-5">
            <h1><span class="brand-color"><strong>Welcome </strong></span><span v-if="hostName !== envAssignHostName">to the <strong>Benefit Store</strong></span> <br> Let's <strong>Get Started</strong></h1>
            <div class="card-block">
              <form action="">
                <div class="form-group row">
                  <div class="col-xxl-6 mb-4">
                    <label for="">Zip Code</label>
                    <input type="number"
                           class="custom-input"
                           v-model="zip"
                           id="zip" value=""
                           @input="$v.zip.$touch(); removeError();"
                           :class="{'form-control is-invalid':$v.zip.$error || formErrors['zip_message']}"
                           placeholder="Enter your ZIP Code"
                           oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                           maxlength="5">
                    <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.zip.required && $v.zip.$error">
                      Field is required
                    </div>
                    <div class="form-group__message  ml-3 mt-2" v-if="$v.zip.required && $v.zip.$error">
                      Invalid Zip code
                    </div>
                    <div class="error_message" v-if="formErrorFlag['zip'] == true">{{formErrors['zip_message']}}</div>
                  </div>
                  <div class="col-xxl-6 mb-4">
                    <label for="">Gender</label>
                    <div>
                      <b-form-select v-model="gender"
                                     class="custom-input"
                                     @input="$v.gender.$touch(); removeError();"
                                     :class="{'form-control is-invalid':$v.gender.$error || formErrors['gender_message']}"
                                     id="gender"
                                     :options="genderOptions">
                      </b-form-select>
                    </div>
                    <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.gender.required && $v.gender.$error">
                      Field is required
                    </div>
                    <div class="error_message" v-if="formErrorFlag['gender'] == true">{{formErrors['gender_message']}}</div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xxl-6 mb-4">
                    <label for="">Choose Tier</label>
                    <b-form-select v-model="tier"
                                   class="custom-input"
                                   @input="$v.tier.$touch(); removeError();"
                                   :class="{'form-control is-invalid':$v.tier.$error || formErrors['tier_message']}"
                                   id="tier"
                                   :options="tierOptions">
                    </b-form-select>
                    <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.tier.required && $v.tier.$error">
                      Field is required
                    </div>
                    <div class="error_message" v-if="formErrorFlag['tier'] == true">{{formErrors['tier_message']}}</div>
                  </div>
                  <div class="col-xxl-6 mb-4">
                    <label for="">DOB</label>
            <!--  "vue2-datepicker": "^3.9.1", Date Picker code-->
<!--                    <date-picker
                        input-class="custom-input"
                        placeholder="mm/dd/yyyy"
                        type="date"
                        IsEditable="true"
                        v-model="dob"
                        @input="$v.dob.$touch(); removeError();"
                        :disabled-date="(date) => date >= new Date()"
                        :class="{'invalid':$v.dob.$error || formErrors['dob_message']}"
                        id="date"
                        format="MM/DD/YYYY"
                        value-type="format">
                      <template #input='{ props, events }'>
                        <input
                            v-bind='props'
                            size=10 maxlength=10
                            onkeyup="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                            v-on='events'>
                      </template>
                    </date-picker>-->
                    <v-date-picker
                        v-model="dob"
                        :popover="{ visibility: 'click' }"
                        :max-date="new Date()"
                        @input="$v.dob.$touch(); removeError(); convertIntoDateFormat();"
                        mode="date">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                            class="custom-input"
                            placeholder="mm/dd/yyyy"
                            size=10 maxlength=10
                            :class="{'invalid':$v.dob.$error || formErrors['dob_message']}"
                            :value="inputValue.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                            v-on="inputEvents"
                        />
                      </template>
                    </v-date-picker>
                    <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.dob.required && $v.dob.$error">
                      Field is required
                    </div>
                    <div class="form-group__message  ml-3 mt-2" v-if="$v.dob.required && $v.dob.$error">
                      You must be 18 years to proceed
                    </div>
                    <div class="error_message" v-if="formErrorFlag['dob'] == true">{{formErrors['dob_message']}}</div>
                  </div>
                </div>
                <div class="form-group mb-3">
                  <button type="button" class="univ-btn" @click="submitAPI">Submit</button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-xxl-7 col-xl-7 col-lg-7 col-12" v-if="siteUrl === 'false'">
            <div class="text-center">
              <img :src="bannerImage" alt="banner-image" class="img-fluid">
            </div>
          </div>
        </div>
      </div>
    </div>
    <featured-plans  class="mt-4" />
  </section>
</template>
<script>
import axios from 'axios';
import FeaturedPlans from "./FeaturedPlans";
import {required} from "vuelidate/lib/validators";
import {checkValidZip, isOver18} from "../utils/validators";
// import  {EventBus} from "../utils/event-bus";


export default {
  name: "Banner",
  components: {
    FeaturedPlans,
  },
  data: function () {
    return {
      showSlide1: true,
      showSlide2: false,
      showSlide3: false,
      slideIndex: 'first',
      buttonText: 'SUBMIT',
      zip: '',
      dob: '',
      state: '',
      gender: '',
      is_smoker: '',
      tier: '',
      eprocess: 'rep',
      agent_id: '10109',
      group_id: '77',
      apiResp: false,
      zipFlag: false,
      secondflag: false,
      thirdflag: false,
      zip_error_message: '',
      second_error_message: '',
      third_error_message: '',
      formErrorFlag: [],
      formErrors: [],
      platformId: '',
      enrollType: '',
      openType: '',
      genderOptions: [
        { value: '', text: 'Choose Gender', disabled: true},
        { value: '0', text: 'Male' },
        { value: '1', text: 'Female'}
      ],
      tierOptions: [
        { value: '', text: 'Choose Tier', disabled: true},
        { value: 'IO', text: 'Member Only'},
        { value: 'IS', text: 'Member + Spouse'},
        { value: 'IC', text: 'Member + Children'},
        { value: 'IF', text: 'Family'}
      ],
      bannerImage: 'https://benefit-store-assets.s3.us-east-2.amazonaws.com/banner.png',
      isPremierPlanAddedOnCart: false,
      siteUrl: process.env.VUE_APP_IS_GO_ENROLL,
      goEnrollLandingImage: '',
      hostName: location.host,
      envAssignHostName: process.env.VUE_APP_SITE_HOST_NAME
    }
  },
  mounted() {
    let app = this;
    // app.getMember();
    // app.getGroups();
    app.tempId = window.localStorage.getItem('tempId');
    app.agent_id = window.localStorage.getItem('AGENT_ID');
    app.group_id = window.localStorage.getItem('group_id');
    app.platformId = window.localStorage.getItem('portal_id');
    app.enrollType = window.localStorage.getItem('enroll_type');
    app.eprocess = window.localStorage.getItem('eprocess');
    app.openType = window.localStorage.getItem('open_type');
    app.getFilters();
    app.checkPremierPlan();
    app.fetchCatchInfo();
  },
  validations: {
    zip: {
      required,
      checkValidZip
    },
    tier: {
      required
    },
    dob: {
      required,
      isOver18
    },
    gender: {
      required
    }
  },
  methods: {
    buttonNavigate: function () {
      switch (this.slideIndex) {
        case "first":
          var zipValid = this.validateZip();
          console.log(zipValid);
          setTimeout(() => {
            if (this.validateZip() == false) {
              console.log('hee');
              this.slideIndex = 'second';
              this.showSlide1 = false;
              this.showSlide2 = true;
            }else{
              console.log('here');
              this.slideIndex = 'first';
              this.showSlide1 = true;
              this.showSlide2 = false;
            }
          }, 2000);
          break;
        case "second":
          if (this.gender !='' && this.is_smoker != '') {
            this.slideIndex = 'third';
            this.showSlide2 = false;
            this.showSlide3 = true;
            this.buttonText = 'SUBMIT';
            this.secondflag = false;
            this.second_error_message = '';
          } else {
            this.slideIndex = 'second';
            this.showSlide2 = true;
            this.showSlide3 = false;
            this.secondflag = true;
            this.second_error_message = 'Please fill the both fields.';
          }
          break;
      }
    },
    viewSlide: function (val) {
      switch (val) {
        case "first":
          this.slideIndex = 'first';
          this.showSlide1 = true;
          this.showSlide2 = false;
          this.showSlide3 = false;
          this.buttonText = 'NEXT';
          break;
        case "second":
          this.slideIndex = 'second';
          this.showSlide1 = false;
          this.showSlide2 = true;
          this.showSlide3 = false;
          this.buttonText = 'NEXT';
          break;
        case "third":
          this.slideIndex = 'third';
          this.showSlide1 = false;
          this.showSlide2 = false;
          this.showSlide3 = true;
          this.buttonText = 'SUBMIT';
          break;
      }
    },
    getFilters: function () {
      let app = this;
      if(app.tempId != null){
        axios.get(process.env.VUE_APP_API_BASE + '/get-enrollment-temp-info', {
          params: {
            enrollment_id: app.tempId,
            column: 'filter_info'
          }
        })
            .then(function (response) {
              if (response.data.status == 'success') {
                app.dob = response.data.data.result.dob;
                app.zip = response.data.data.result.zip;
                app.state = response.data.data.result.state;
                app.tier = response.data.data.result.tier;
                app.gender = response.data.data.result.gender;
              }
            })
            .catch(function (error) {
              console.log(error);
            });
      }
    },
    submitAPI: function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false
      } else {
        // Submit API and
        // Navigate to plans page
        let app = this;
        let params = {
          zip: app.zip,
          dob: app.dob,
          is_smoker: app.is_smoker,
          gender: app.gender,
          tier: app.tier,
          eprocess: app.eprocess,
          agent_id: app.agent_id,
          group_id: app.group_id,
          annual_incode: app.annualIncome,
          user_id: app.userId,
          open_type: app.open_type,
        };
        if (app.tempId == null) {
          axios.post(process.env.VUE_APP_API_BASE + '/add-filter-for-new-user', {
            zip: app.zip,
            dob: app.dob,
            // is_smoker: app.is_smoker,
            gender: app.gender,
            tier: app.tier,
            eprocess: app.eprocess,
            agent_id: app.agent_id,
            group_id: app.group_id,
            platform_group_id: app.platformId,
            type: app.enrollType,
            open_type: app.openType,
          })
              .then(function (response) {
                if (response.data.status == 'success') {
                  window.localStorage.setItem('tempId', response.data.data.temp_enrollment_id);
                  window.localStorage.setItem('show-quotation', true);
                  window.localStorage.setItem('isFilterDataSubmitted', true);
                  window.localStorage.removeItem('isCurrentMemberAvailable')
                  app.formErrorFlag = [];
                  app.formErrors = [];
                  app.$bus.$emit('getMenuList')
                  if (app.tempId != null) {
                    app.$root.$emit("cartData", app.tempId);
                  }
                  app.nextPage(true);
                }
              })
              .catch(function (error) {
                app.formErrorFlag = [];
                app.formErrors = [];
                console.log(error);
                if (error.response.data.status == 'error') {
                  error.response.data.data.forEach(function (item) {
                    app.formErrorFlag[item.target_element] = true;
                    app.formErrors[item.target_element + '_message'] = item.error_message;
                  });
                }
              });
        } else {
          for (const key of Object.keys(params)) {
            if (params[key] === null || params[key] === '' || params[key] === undefined) {
              delete params[key];
            }
          }
          axios.put(process.env.VUE_APP_API_BASE + '/change-filter-info/' + app.tempId, params)
              .then(function (response) {
                if (response.data.status == 'success') {
                  app.isHidden = false
                  window.localStorage.setItem('isFilterDataSubmitted', true);
                  window.localStorage.removeItem('isCurrentMemberAvailable')
                  app.getFilters();
                  app.$bus.$emit('getMenuList')
                  if (app.tempId != null) {
                    app.$root.$emit("cartData", app.tempId);
                  }
                  app.nextPage(true);
                }
              })
              .catch(function (error) {
                app.formErrorFlag = [];
                app.formErrors = [];
                console.log(error);
                if (error.response.data.status == 'error') {
                  error.response.data.data.forEach(function (item) {
                    app.formErrorFlag[item.target_element] = true;
                    app.formErrors[item.target_element + '_message'] = item.error_message;
                  });
                }
              });
        }
      }
    },
    validateZip: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/validate-zip-code', {
        params: {
          zip_code: app.zip,
        },
      })
          .then(function (response) {
            console.log(response);
            app.zip_error_message = '';
            app.zipFlag = false;
          })
          .catch(function (error) {
            console.log(error.response.data.data[0]);
            if (error.response.data.status == 'error') {
              // error.response.data.data.forEach(function(item) {
              app.zipFlag = true;
              app.zip_error_message = error.response.data.data[0].error_message;
              // });
            }
          });
      return app.zipFlag;
    },
    nextPage: function(respFlag) {
      if (respFlag == true) {
        this.$router.push('plans');
      }
    },
    checkValue: function(str, max) {
      if (str.charAt(0) !== '0' || str == '00') {
        var num = parseInt(str);
        if (isNaN(num) || num <= 0 || num > max) num = 1;
        str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
      }
      return str;
    },
    eventDob: function() {
      let app = this;
      var input = app.dob;
      if (/\D\/$/.test(input)) input = input.substr(0, input.length - 1);
      var values = input.split('/').map(function(v) {
        return v.replace(/\D/g, '')
      });
      if (values[0]) values[0] = app.checkValue(values[0], 12);
      if (values[1]) values[1] = app.checkValue(values[1], 31);
      var output = values.map(function(v, i) {
        return v.length == 2 && i < 2 ? v + '/' : v;
      });
      app.dob = output.join('').substr(0, 10);
    },
    removeError () {
      this.formErrors = []
    },
    homePage: function(){
      if (this.open_type !== null && typeof(this.open_type) !== 'undefined') {
        if (this.referral !== null && typeof(this.referral) !== 'undefined') {
          this.$router.push('/referrals?rid='+this.referral);
        } else {
          this.$router.push('/home');
        }
      } else {
        if ((this.eprocess === 'applicant' && this.openType === 'agent') || (this.eprocess === 'applicant' && this.openType === 'group')) {
          this.$router.push('/');
        } else if (this.eprocess === 'rep') {
          this.$router.push('/agent-home');
        } else if (this.eprocess === 'applicant') {
          this.$router.push('/member-home');
        } else if (this.eprocess === 'group') {
          if (location.host == process.env.VUE_APP_SITE_HOST_NAME) {
            this.$router.push('/');
          } else {
            this.$router.push('/group-home');
          }
        }
      }
    },
    checkPremierPlan () {
      // Check L713 plan on cart.
      let app = this
      if (app.tempId !== null) {
        axios.get(process.env.VUE_APP_API_BASE+'/get-more-requirement', {
          params: {
            enrollment_id: app.tempId,
          }
        })
          .then(function (response) {
            app.isPremierPlanAddedOnCart = response.data.data
            if (app.isPremierPlanAddedOnCart) {
              app.tierOptions = [
                {value: '', text: 'Choose Tier', disabled: true},
                {value: 'IO', text: 'Member Only'},
                {value: 'IS', text: 'Member + Spouse'},
                {value: 'IC', text: 'Member + 1 Child'},
                {value: 'IF', text: 'Family'}
              ]
            } else {
              app.tierOptions = [
                {value: '', text: 'Choose Tier', disabled: true},
                {value: 'IO', text: 'Member Only'},
                {value: 'IS', text: 'Member + Spouse'},
                {value: 'IC', text: 'Member + Children'},
                {value: 'IF', text: 'Family'}
              ]
            }
          })
          .catch(function (error) {
            console.log(error)
          });
      }
    },
    fetchCatchInfo() {
      let siteDomain = window.btoa(unescape(encodeURIComponent(process.env.VUE_APP_SITE_DOMAIN)))
      // let siteDomain = 'aHR0cHM6Ly9lbnJvbGwucHVyZW5yb2xsLmNvbQ=='
      let url = `${process.env.VUE_APP_API_BASE}/get-external-site-dynamic-information?site_domain=${siteDomain}`
      caches.open('fetch_color_cache').then((cache) => {
        cache.match(url).then(response => {
          response.json().then(data => {
            let getCatchColor = []
            let newObj = {}
            getCatchColor = data
            getCatchColor.map(item => {
              newObj[item.key] = item.value;
            });
            this.bannerImage = newObj["VUE_APP_BANNER_IMAGE"]
            this.goEnrollLandingImage = newObj["VUE_APP_GO_ENROLL_BANNER_IMAGE"]
          })
        })
      }).catch((err) => {
        console.log(err);
      })
    },
    convertIntoDateFormat () {
      let app = this
      let date = this.dob
      let dd = date.getDate()
      let mm = date.getMonth() + 1
      let yyyy = date.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      app.dob = mm + '/' + dd + '/' + yyyy;
    },
  },
  listener: {
    'getGroupDetail'() {
      if (location.host == process.env.VUE_APP_SITE_HOST_NAME) {
        let app = this
        app.group_id = window.localStorage.getItem('group_id');
        app.platformId = window.localStorage.getItem('portal_id');
      }
    }
  }
}
</script>
